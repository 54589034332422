import { Middleware } from 'redux';
import { State } from '../store';

import { config } from '../utils';

import { getCarSuccess, setCar } from '../store/cars/actions';
import { getCarsRelated } from '../store/carsRelated/actions';
import { defaultCountCarsRelated } from '../selectors/getCarsRelated';

const getCarsRelatedMiddleware: Middleware<{}, State> = (store) => (next) => (
  action
) => {
  // Запрашиваем похожие авто
  if (getCarSuccess.match(action) || setCar.match(action)) {
    const { carsRelated } = config.getConfigObject();

    if (carsRelated && action.payload) {
      const { getReqParams } = carsRelated;
      let reqParams;

      // Получаем дополнительные параметры для запроса, индивидуально для каждого авто
      if (typeof getReqParams === 'function') {
        reqParams = getReqParams(action.payload);
      }
      const { dealer, brand, grade, group } = action.payload;
      const { count = defaultCountCarsRelated } = carsRelated;

      // Добавляем +1, т.к. текущее авто не показываем (если оно попало в ответ от api)
      const per_page = count + 1;

      store.dispatch(
        getCarsRelated({
          dealer: dealer?.id,
          state: 'active',
          hidden: false,
          brand,
          grade,
          group,
          per_page,
          ...reqParams,
        })
      );
    }
  }

  next(action);
};

export default getCarsRelatedMiddleware;
