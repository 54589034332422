import { createAction, Dispatch, AnyAction } from '@reduxjs/toolkit';
import { RSAA, RSAAAction } from 'redux-api-middleware';
import queryStringify from 'qs/lib/stringify';
import resolveUrl from 'resolve-url';
import set from 'lodash.set';
import { getCars } from '../../../selectors';

import {
  Car,
  Cars,
  ApiMiddlewareError,
  CarsState,
  FilterValue,
  Fields,
  Ranges,
} from '../types';

import { State } from '../../index';

import { getPageCount } from '../../../selectors';
import { config, abortMap } from '../../../utils';

/**
 * Получение автомобиля c API
 */

export const getCarRequest = createAction('getCarRequest');
export const getCarSuccess = createAction<Car>('getCarSuccess');
export const getCarError = createAction<ApiMiddlewareError>('getCarError');

export const getCar = (carId: string): RSAAAction => {
  return {
    [RSAA]: {
      endpoint: resolveUrl(config.get('carStockApi'), `./vehicles/${carId}`),
      method: 'GET',
      types: [getCarRequest.type, getCarSuccess.type, getCarError.type],
    },
  };
};

/**
 * Очистка автомобиля
 */

export const clearCar = createAction('clearCar');

/**
 * Установка атомобиля
 */

export const setCar = createAction<Car>('setCar');

/**
 * Получение автомобиля cо стора или от API
 */

export const getCarFromStoreOrApi = (carId: string) => (
  dispatch: Dispatch,
  getState: () => State
) => {
  const error = 'car not found in store';

  try {
    const state = getState();
    const cars = getCars(state);

    if (!cars) throw error;

    const car = cars.find(({ id }) => id === carId);

    if (!car) throw error;

    dispatch(setCar(car));
  } catch (e) {
    if (e !== error) throw e;

    dispatch(getCar(carId));
  }
};

/**
 * Получение queries id на отфильтрованный список автомобилей
 */

type Filters = {
  [key: string]: any; // TODO: уточнить тип Filters
};

type Queries = {
  id: string;
  hash: string;
  queries: Filters[];
};

export const postQueriesRequest = createAction<string>('postQueriesRequest');
export const postQueriesSuccess = createAction<Queries>('postQueriesSuccess');
export const postQueriesError = createAction<ApiMiddlewareError>(
  'postQueriesError'
);

export const postQueries = (filters: Filters): RSAAAction => {
  const { id, signal } = abortMap.create();

  const resolvedFiltres = Object.keys(filters).reduce<Filters>((acc, name) => {
    set(acc, name, filters[name]);
    return acc;
  }, {});

  return {
    [RSAA]: {
      endpoint: resolveUrl(config.get('carStockApi'), './queries/'),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        queries: [resolvedFiltres],
      }),
      types: [
        {
          type: postQueriesRequest.type,
          payload: id,
        },
        postQueriesSuccess.type,
        postQueriesError.type,
      ],
      options: {
        signal,
      },
    },
  };
};

/**
 * Получение сокращенных результатов поиска по queriesId
 */

type QueriesSummary = {
  count: number;
}[];

export const getQueriesSummaryRequest = createAction<string>(
  'getQueriesSummaryRequest'
);

export const getQueriesSummarySuccess = createAction<QueriesSummary>(
  'getQueriesSummarySuccess'
);

export const getQueriesSummaryError = createAction<ApiMiddlewareError>(
  'getQueriesSummaryError'
);

export const getQueriesSummary = (queriesId: string): RSAAAction => {
  const { id, signal } = abortMap.create();

  return {
    [RSAA]: {
      endpoint: resolveUrl(
        config.get('carStockApi'),
        './queries/',
        `./${queriesId}/`,
        './summary'
      ),
      method: 'GET',
      types: [
        {
          type: getQueriesSummaryRequest.type,
          payload: id,
        },
        getQueriesSummarySuccess.type,
        getQueriesSummaryError.type,
      ],
      options: {
        signal,
      },
    },
  };
};

/**
 * Получение отфильтрованного списка автомобилей по queries id
 */
export const getCarsByQueriesIdRequest = createAction(
  'getCarsByQueriesIdRequest',
  (id: string, page?: number, sort?: string) => {
    return {
      payload: id,
      meta: {
        page,
        sort,
      },
    };
  }
);

type GetCarsByQueriesIdSuccessPayload = {
  items: Cars;
  page?: number;
  sort?: string;
};

export const getCarsByQueriesIdSuccess = createAction<
  GetCarsByQueriesIdSuccessPayload
>('getCarsByQueriesIdSuccess');

export const getCarsByQueriesIdError = createAction<ApiMiddlewareError>(
  'getCarsByQueriesIdError'
);

export const getCarsByQueriesId = (
  queriesId: string,
  perPage?: number,
  page?: number,
  sort?: string
): RSAAAction => {
  const { id, signal } = abortMap.create();

  return {
    [RSAA]: {
      endpoint: resolveUrl(
        config.get('carStockApi'),
        './queries/',
        `./${queriesId}/`,
        `./result`,
        `?${queryStringify({
          per_page: perPage,
          page,
          sort,
        })}`
      ),
      method: 'GET',
      types: [
        getCarsByQueriesIdRequest(id, page, sort),
        {
          type: getCarsByQueriesIdSuccess.type,
          payload: (
            action: ReturnType<typeof getCarsByQueriesId>,
            state: CarsState,
            res: any // TODO: должно быть Response
          ): GetCarsByQueriesIdSuccessPayload =>
            res.json().then((cars: Cars) => {
              return {
                items: cars,
                ...(typeof page === 'number' && { page }),
                ...(typeof sort === 'string' && { sort }),
              };
            }),
        },
        getCarsByQueriesIdError.type,
      ],
      options: {
        signal,
      },
    },
  };
};

/**
 * Получение следующей страници отфильтрованного списка автомобилей
 */
export const getNextPage = (
  pageCountSelector = getPageCount,
  callback = getCarsByQueriesId
) => (dispatch: Dispatch, getState: () => State) => {
  const state = getState();

  const {
    cars: {
      data: { queriesId, sort, page, perPage },
    },
  } = state;

  if (!queriesId || typeof page !== 'number') return;

  const nextPage = page + 1;
  const pageCount = pageCountSelector(state);

  if (pageCount && nextPage <= pageCount - 1) {
    dispatch(callback(queriesId, perPage, nextPage, sort));
  }
};

/**
 * Смена сортировки
 */
export const setSort = (sort: string, callback = getCarsByQueriesId) => (
  dispatch: Dispatch,
  getState: () => State
) => {
  const state = getState();

  const {
    cars: {
      data: { queriesId, page, perPage },
    },
  } = state;

  if (!queriesId || typeof page !== 'number') return;

  // сбрасываем пагинацию на первую страницу (page = 0), при изменении фильтра сортировки
  dispatch(callback(queriesId, perPage, 0, sort));
};

/**
 * Получение возможных значений для фильтров
 */
export const getQueriesValuesRequest = createAction<string>(
  'getQueriesValuesRequest'
);

export const getQueriesValuesSuccess = createAction<FilterValue[]>(
  'getQueriesValuesSuccess'
);

export const getQueriesValuesError = createAction<ApiMiddlewareError>(
  'getQueriesValuesError'
);

export const getQueriesValues = (
  queriesId: string,
  filters: string[]
): RSAAAction | AnyAction | any => {
  // TODO: any это отстой, нужно помирить с TypeScript

  if (!Array.isArray(filters) || filters.length === 0)
    return { type: 'getQueriesValuesEmpty' };

  const excludedQueryParams: string[] = config.get('excludedQueryParamsGetValues') || [];

  const query = queryStringify(
    {
      field: filters.filter((filter) => excludedQueryParams.indexOf(filter) === -1),
    },
    { arrayFormat: 'comma' }
  );

  const { id, signal } = abortMap.create();

  return {
    [RSAA]: {
      endpoint: resolveUrl(
        config.get('carStockApi'),
        './queries/',
        `./${queriesId}/`,
        './values',
        `?${query}`
      ),
      method: 'GET',
      types: [
        {
          type: getQueriesValuesRequest.type,
          payload: id,
        },
        getQueriesValuesSuccess.type,
        getQueriesValuesError.type,
      ],
      options: {
        signal,
      },
    },
  };
};

/**
 * Работа с фильтрами
 */
export const filtersUpdate = createAction(
  'filtersUpdate',
  (payload: Fields, meta: { updateUrl: boolean } = { updateUrl: true }) => {
    return {
      payload,
      meta,
    };
  }
);
export const filtersReset = createAction('filtersReset');

/**
 * Обновление возможных значений range полей
 */
export const rangesUpdate = createAction<Ranges>('rangesUpdate');

export default {
  getCar,
  clearCar,
  getCarFromStoreOrApi,
  postQueries,
  getQueriesSummary,
  getCarsByQueriesId,
  getNextPage,
  setSort,
  getQueriesValues,
  filtersUpdate,
  rangesUpdate,
  filtersReset,
};
